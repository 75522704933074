.description-course {
  padding: 30px 0;
  width: 100%;
  .description-container {
    width: 66%;
    padding-left: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;
    h3 {
      font-family: Lato;
      font-size: 28px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0.24px;
    }
    ol {
      li {
        margin-left: 20px;
        list-style: decimal;
        font-family: Lato;
        font-size: 27px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0.16px;
        color: rgba(185, 185, 185, 1);
      }
    }
  }
}
@media only screen and (max-width: 950px) {
  .description-course {
    .description-container {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 650px) {
  .description-course {
    .description-container {
      padding: 0px;
    }
  }
}
