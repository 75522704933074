.course-content-course {
  padding: 30px 0;
  width: 100%;
  .course-content {
    width: 64%;
    padding-left: 60px;
    display: flex;
    flex-direction: column;
    gap: 16px;
   
    &-head {
      display: flex;
      flex-direction: column;
      gap: 24px;
      h5 {
        color: #fff;
        font-family: Lato;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
      }
      span {
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.16px;
        color: rgba(185, 185, 185, 1);
        font-family: 'Roboto', sans-serif !important;
      }
    }
    &-lists {
      display: flex;
      flex-direction: column;
      .list {
        border: 1px solid #794c00;
        display: flex;
        flex-direction: column;
        padding: 16px;
        &-first {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &-left-part {
            display: flex;
            align-items: center;
            gap: 12px;
            img {
              width: 24px;
              height: 24px;
            }
            span {
              font-family: Lato;
              font-size: 20px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0.2px;
              color: rgba(243, 243, 243, 1);
            }
            .rotate {
              transform: rotate(180deg);
              //   transition: transform 0.3s ease;
            }
          }
          &-right-part {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 10px;

            span {
              font-family: Lato;
              font-size: 16px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0.16px;
              color: rgba(209, 209, 209, 1);
            }
          }
        }
        &-second {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          padding: 16px 0;
          gap: 20px;
          .isExpanded {
            font-size: 14px;
            color: rgba(209, 209, 209, 1);
            width: 77%;
            padding-left: 37px;
          }
          &-one {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &-left {
              display: flex;
              align-items: center;
              gap: 13px;
              h6 {
                font-size: 16px;
                font-family: Lato;
                font-weight: 400;
              }
              .vector {
                background-color: rgb(49, 49, 49);
                padding: 5px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  width: 10px;
                  height: 10px;
                }
              }
            }
            &-right {
              display: flex;
              align-items: center;
              gap: 40px;
              h6 {
                font-family: Lato;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
              }
              span {
                font-family: Lato;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.16px;
                color: rgba(209, 209, 209, 1);
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 950px) {
  .course-content-course {
    .course-content {
      width: 96%;
      &-lists {
        .list {
          &-second {
            .isExpanded {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .course-content-course {
    .course-content {
      width: 100%;
      padding: 0;
      &-lists {
        .list {
          &-first{
            flex-direction: column;
            img{
              width: 14px;
              height: 14px;
            }
            span{
              font-size: 14px;
line-height: 17px;
            }
          }
        
          &-second {
            gap: 20px;
            .isExpanded {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
