.header-general {
  position: fixed;
  top: 0;
  background-color: rgba(18, 18, 18);
  display: flex;
  width: 100%;
  .header-left {
    display: flex;
    width: 31.2%;
    
    padding: 13px 32px;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-right: 1px solid #d58b0e;
    .icon-settings {
      position: relative;
      cursor: pointer !important;
      z-index: 99;
    }
    .player-settings {
      width: 220px;
      position: absolute;
      right: 0;
      top: 80px;
      background-color: #222;
      padding: 12px 16px;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      opacity: 0;
      transition: opacity 1s ease;
      .field {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        span {
          color: #fff;
          font-family: Lato;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
  }
  .header-right {
    display: flex;
    width: 68.8%;
    padding: 13px 24px;
    justify-content: flex-end;
    align-items: center;
    gap: 98px;
    flex-shrink: 0;
    button {
      display: flex;
      height: 44px;
      padding: 5px 16px;
      align-items: center;
      gap: 16px;
      background-color: transparent;
      border-radius: 2px;
      border: 1.5px solid #d58b0e;
      color: #f3f3f3;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      .right {
        width: 24px;
        height: 24px;
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .header-general {
    .header-left {
      width: 100%;
      border-right: none;
    }
    .header-right {
      display: none;
    }
  }
}
@media only screen and (max-width: 450px) {
  .header-general {
    .header-left {
      width: 35%;
      border-right: none;
    }
    .header-right {
      display: none;
    }
  }
}
